export const id = {
  translation: {
    "Welcome back": "Selamat datang kembali",
    actions: "Aksi",
    cancel: "Batal",
    category: "Kategori",
    create: "Buat",
    createdAt: "Dibuat pada",
    delete: "Hapus",
    deleteCancel: "Batal menghapus {{ data }}!",
    deleteConfirmation: "Apakah Anda yakin ingin menghapus {{ data }}?",
    deleteProgress: "Menghapus {{ data }} dalam proses...",
    deleteSuccess: "Berhasil menghapus {{ data }}!",
    description: "Deskripsi",
    edit: "Ubah",
    email: "Email",
    firstPage: "Halaman Pertama",
    id: "ID",
    iswhatsapp: "Terdaftar di Whatsapp",
    lastcheck: "Terakhir dicek",
    loadmore: "Muat Lebih Banyak",
    message_select: "Pilih pesan untuk memulai percakapan",
    name: "Nama",
    next: "Selanjutnya",
    notregistered: "Tidak Terdaftar",
    openinfull: "Klik untuk membuka dalam layar penuh",
    phone: "Telepon",
    previous: "Sebelumnya",
    registered: "Terdaftar",
    save: "Simpan",
    Search: "Cari proyek…",
    slug: "Slug",
    status: "Status",
    unknown: "Tidak diketahui",
    updatedAt: "Diperbarui pada",
    cancle: "Batal",
    saveUpdate: "Simpan Perubahan",
    loading: "Memuat...",
    select: "Pilih",
    messageTemplate: {
      name: {
        title: "Nama",
        description: "Nama dari template pesan",
      },
      code: {
        title: "Template Code",
        description:
          "Template Code digunakan untuk mengidentifikasi template pesan",
      },
      category: {
        title: "Kategori",
        description: "Kategori dari template pesan",
      },
      language: {
        title: "Bahasa",
        description: "Bahasa dari template pesan",
      },
      transactional: {
        title: "Transaksi",
        description:
          "Kirim pembaruan akun, pembaruan pesanan, peringatan dan lainnya untuk membagikan informasi penting.",
      },
      otp: {
        title: "Kode Satu Kali",
        description:
          "Kirim kode yang memungkinkan pelanggan Anda mengakses akun mereka.",
      },
      utility: {
        title: "Utilitas",
        description:
          "Memfasilitasi permintaan atau transaksi tertentu yang disetujui atau pembaruan kepada pelanggan tentang transaksi yang sedang berlangsung, termasuk pemberitahuan pasca pembelian dan laporan tagihan berulang.",
      },
      marketing: {
        title: "Pemasaran",
        description:
          "Cantumkan promosi atau penawaran, pembaruan informasi, atau undangan bagi pelanggan untuk menanggapi/mengambil tindakan. Percakapan apa pun yang tidak memenuhi syarat sebagai utilitas atau autentikasi adalah percakapan pemasaran.",
      },
      authentication: {
        title: "Otentikasi",
        description:
          "Memungkinkan bisnis mengautentikasi pengguna dengan kode sandi satu kali, kemungkinan pada beberapa langkah dalam proses masuk (misalnya: verifikasi akun, pemulihan akun, tantangan integritas).",
      },
      validateCode: "Mencari kode duplikat... Mohon tunggu...",
      exist:
        "Template dengan {{ data }} sudah ada, silakan gunakan Nama atau Code yang lain",
    },
    messageTemplateLang: {
      header: {
        title: "Header Pesan",
        description: "Tambahkan media header untuk template ini",
      },
      body: {
        title: "Body Pesan",
        description: "Tambahkan pesan body untuk template ini",
      },
      params: {
        title: "Body Parameters",
        description: "Tambahkan body parameters untuk template ini",
      },
      footer: {
        title: "Footer Pesan",
        description: "Tambahkan pesan footer untuk template ini",
      },
      button: {
        title: "Button Pesan",
        description: "Tambahkan pesan button untuk template ini",
      },
      buttonList: ["action"],
      process: "Kami sedang memproses permintaan Anda. Silakan tunggu...",
      addSecurityRecommendation: {
        title: "Tambahkan Rekomendasi Keamanan",
        description: "Tambahkan rekomendasi keamanan untuk template ini",
        text: "Demi keamanan, jangan bagikan kode ini",
      },
      codeExpiration: {
        title: "Kode Kadaluarsa",
        description: "Kode kadaluarsa untuk template ini (dalam menit)",
        help: "Kode kadaluarsa harus antara 1 - 90 menit",
        invalid: "Anda harus memasukkan waktu kadaluarsa yang valid",
      },
      copyCode: {
        title: "Salin Kode",
        description: "Atur Tulisan Tombol Untuk Menyalin Kode",
      },
    },
    language: "Bahasa",
    none: "Tidak Ada",
    image: "Gambar",
    document: "Dokumen",
    validUpload: "Silakan unggah {{ data }} yang valid",
    imageAllowed: "Jenis gambar yang diizinkan: .jpg, .jpeg, .png",
    documentAllowed:
      "Jenis dokumen yang diizinkan: .jpeg, .jpg, .png, .doc, .docx, .pdf, .csv, .ppt, .pptx, .xls, .xlsx",
    optional: "Opsional",
    show: "Tampilkan",
    profileDetails: "Detail Profil",
    about: "Tentang",
    quotaDefault: "Kuota Bawaan",
    quotaFree: "Kuota Gratis",
    balance: "Saldo",
    search: "Cari",
    sessionActive:
      "Silakan kirim pesan template atau kirim pesan ke nomor whatsapp untuk mengaktifkan pesan balasan, setiap sesi akan kedaluwarsa dalam 24 jam",
    send: "Kirim",
    replyMessage: "Kirim pesan balasan...",
    askReply: "Apakah Anda yakin ingin mengirim balasan dengan {{ data }}?",
    sendReply: "{{ info }} balasan dengan {{ data }}",
    dashboard: {
      totalInbox: "Total Pesan Masuk",
      totalOutbox: "Total Pesan Keluar",
      totalMessage: "Total Pesan Terkirim",
      totalMessageFailed: "Total Pesan Gagal",
      statistics: "Statistik",
    },
    sent: "Terkirim",
    failed: "Gagal",
    delivered: "Dikirim",
    read: "Dibaca",
    time: "Waktu",
    received: "Diterima",
    templateName: "Nama Template",
    quickReply: "Balasan Cepat",
    phoneNumber: "Nomor Telepon",
    url: "URL",
    callback: "URL Callback",
    headerName: "Nama Header",
    headerValue: "Isi Header",
    emptyFormOptional: "Biarkan kosong jika Anda ingin menjadikannya opsional",
    tokens: "Token",
    generate: "Buat",
    password: "Kata Sandi",
    passwordHelp: "Masukkan kata sandi akun anda untuk melanjutkan",
    idToken: "ID Token",
    idTokenHelp:
      "ID Token digunakan untuk mengotentikasi permintaan Anda dengan API dan Aktif Selama 24 Jam",
    refreshToken: "Refresh Token",
    refreshTokenHelp:
      "Refresh Token digunakan untuk memperbarui ID Token Anda dan Aktif Selamanya, Harap Simpan Dengan Aman",
    deviceId: "Device ID",
    deviceIdHelp:
      "Device ID digunakan untuk mengidentifikasi perangkat Anda dan Aktif Selamanya, Harap Simpan Dengan Aman",
    templateNotFound:
      "Template Pesan Tidak Ditemukan, Silahkan buat template baru untuk menggunakan fitur ini",
    templateNew: "Buat Template Baru",
    templateNotApproved:
      "Template anda belum disetujui, silahkan tunggu persetujuan dari staf whatsapp. Jika template anda ditolak, anda bisa melihat alasan penolakan. Jika anda membutuhkan bantuan, silahkan hubungi kami.",
    templateReject:
      "Maaf, template Anda telah ditolak. Silakan periksa alasan di bawah ini.",
    templateButtonUrlHelp:
      "URL Template Button Anda membutuhkan URL Dinamis di akhir, URL Anda {{url}} dan URL Dinamis {{dynamicUrl}}. Pastikan ini sama dengan URL Template Button Anda.",
    new: "Baru",
    replied: "Dibalas",
    button: {
      title: "Button",
      description: "Tambahkan button untuk template ini",
    },
    from: "Dari",
    type: "Tipe",
    message: "Pesan",
    reduceBalance:
      "Saldo quotaBase {{ quotaBase }}, Saldo Deposit {{ balance }}, Dikurangi Dari {{ reduce }} dengan {{ total }}, Sisa Saldo {{ reduce }} {{ remaining }}",
    sessionInfo:
      "Sesi dikalkulasi didasarkan pada kategori dan akan tetap aktif selama 24 jam. Jika Anda mengirim pesan dengan kategori yang berbeda, sesi akan dibuka kembali berdasarkan kategori baru tersebut",
    balanceReduce:
      "Mengirim Pesan Ke {{ phone }}, sistem akan mengurangi kuota/saldo",
    balanceNotReduce:
      "Mengirim Pesan Ke {{ phone }}, sistem tidak akan mengurangi kuota/saldo karena sesi aktif (24 jam)",
    newMessage: "Pesan Baru Masuk Dari {{ phone }}",
    yes: "Ya",
    no: "Tidak",
  },
};
