export const SIDEBAR_POSITION = {
  LEFT: "left",
  RIGHT: "right",
};

export const SIDEBAR_BEHAVIOR = {
  STICKY: "sticky",
  FIXED: "fixed",
  COMPACT: "compact",
};

export const LAYOUT = {
  FLUID: "fluid",
  BOXED: "boxed",
};

export const THEME = {
  DEFAULT: "default",
  COLORED: "colored",
  DARK: "dark",
  LIGHT: "light",
};

export const THEME_PALETTE_LIGHT = {
  primary: "#3B82EC",
  "primary-dark": "#1659c7",
  "primary-light": "#84aef2",
  secondary: "#495057",
  success: "#4BBF73",
  info: "#1F9BCF",
  warning: "#f0ad4e",
  danger: "#d9534f",
  white: "#fff",
  "gray-100": "#f4f7f9",
  "gray-200": "#e2e8ee",
  "gray-300": "#dee6ed",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#020202",
  "gray-900": "#212529",
  black: "#000",
};

export const THEME_PALETTE_DARK = {
  ...THEME_PALETTE_LIGHT,
  "primary-dark": "#84aef2",
  "primary-light": "#1659c7",
  white: "#293042",
  "gray-100": "#3e4555",
  "gray-200": "#545968",
  "gray-300": "#696e7b",
  "gray-400": "#7f838e",
  "gray-500": "#9498a1",
  "gray-600": "#a9acb3",
  "gray-700": "#bfc1c6",
  "gray-800": "#d4d6d9",
  "gray-900": "#eaeaec",
  black: "#fff",
};

export const API_URL = process.env.REACT_APP_API_URL;

export const SWR_DEFAULT_CONFIG = {
  suspense: true,
};

export const SWR_NO_REVALIDATE = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true, // If false, undefined data gets cached against the key.
  dedupingInterval: 3_600_000, // dont duplicate a request w/ same key for 1hr
};

export const TEMPLATE_MESSAGE_VALUE = {
  phone: "",
  template_name: "",
  template_language: "",
  template: "",
};

export const TEMPLATE_HEADER_VALUE = {
  type: "none",
  url: "",
  filename: "",
  mime: "",
};

export const TEMPLATE_BODY_VALUE = {
  messages: "",
  parameters: {},
  footer: "",
  button: "none",
};

export const TEMPLATE_BUTTON_VALUE = {
  phone: {
    isShow: false,
    type: "PHONE_NUMBER",
    title: "",
    text: "",
  },
  url: {
    isShow: false,
    type: "URL",
    title: "",
    text: "",
    example: "",
  },
  quick_reply: {
    isShow: false,
    type: "QUICK_REPLY",
    fields: [] as string[],
  },
  otp: {
    isShow: false,
    type: "OTP",
    title: "",
    text: "",
    otp_type: "",
  },
};

export const TEMPLATE_APPROVED_VALUE = {
  isApproved: false,
  status: "PENDING",
  rejectReason: "",
  color: "",
};

export const DEVICE = {
  phoneNumber: "",
  whatsappApplicationId: "",
  whatsapp: {
    profilePicture: "",
    website: [],
    name: "",
    description: "",
    isGreenVerified: false,
    isOfficialBusinessAccount: true,
    category: "",
    email: "",
  },
  callback: {
    headerValue: "",
    url: "",
    headerName: "",
  },
  status: "",
  quotaBase: 0,
  ownerId: "",
  balance: 0,
  dailyLimitMessage: 0,
  rates: "",
  id: "",
  createdAt: "2023-01-01T00:00:00.000Z",
  updatedAt: "2023-01-01T00:00:00.000Z",
  expiredAt: "2023-01-01T00:00:00.000Z",
};

export const DEVICE_API = {
  password: "",
  TokenType: "",
  ExpiresIn: 0,
  AccessToken: "",
  IdToken: "",
  RefreshToken: "",
  NewDeviceMetadata: {
    DeviceKey: "",
    DeviceGroupKey: "",
  },
};
