export const en = {
  translation: {
    "Welcome back": "Welcome back",
    actions: "Actions",
    cancel: "Cancel",
    category: "Category",
    create: "Create",
    createdAt: "Created At",
    delete: "Delete",
    deleteCancel: "Cancel Delete {{ data }}!",
    deleteConfirmation: "Are you sure you want to delete {{ data }}?",
    deleteProgress: "Deleting {{ data }} in progress...",
    deleteSuccess: "Delete {{ data }} successfully!",
    description: "Description",
    edit: "Edit",
    email: "Email",
    firstPage: "First Page",
    id: "ID",
    iswhatsapp: "Registered on Whatsapp",
    lastcheck: "Last Check",
    loadmore: "Load More",
    message_select: "Select a message to start a conversation",
    name: "Name",
    next: "Next",
    notregistered: "Not Registered",
    openinfull: "Click to open in full screen",
    phone: "Phone",
    previous: "Previous",
    registered: "Registered",
    save: "Save",
    Search: "Search projects…",
    slug: "Slug",
    status: "Status",
    unknown: "Unknown",
    updatedAt: "Updated At",
    cancle: "Cancel",
    saveUpdate: "Save Update",
    loading: "Loading...",
    select: "Select",
    messageTemplate: {
      name: {
        title: "Name",
        description: "Name of the message template",
      },
      code: {
        title: "Template Code",
        description: "Template Code used to identify the message template",
      },
      category: {
        title: "Category",
        description: "Category of the message template",
      },
      language: {
        title: "Language",
        description: "Language of the message template",
      },
      transactional: {
        title: "Transactional",
        description:
          "Send account updates, order updates, alerts and more to share important information.",
      },
      otp: {
        title: "One Time Password",
        description:
          "Send codes that allow your customers to access their accounts.",
      },
      utility: {
        title: "Utility",
        description:
          "Facilitate a specific, agreed-upon request or transaction or update to a customer about an ongoing transaction, including post-purchase notifications and recurring billing statements.",
      },
      marketing: {
        title: "Marketing",
        description:
          "Include promotions or offers, informational updates, or invitations for customers to respond / take action. Any conversation that does not qualify as utility or authentication is a marketing conversation.",
      },
      authentication: {
        title: "Authentication",
        description:
          "Enable businesses to authenticate users with one-time passcodes, potentially at multiple steps in the login process (e.g., account verification, account recovery, integrity challenges).",
      },
      validateCode: "Searching for duplicate code... Please wait...",
      exist:
        "Template with {{ data }} already exist, please use another Name or Code",
    },
    messageTemplateLang: {
      header: {
        title: "Header Message",
        description: "Add header media for this template",
      },
      body: {
        title: "Body Message",
        description: "Add body message for this template",
      },
      params: {
        title: "Body Parameters",
        description: "Add body parameters for this template",
      },
      footer: {
        title: "Footer Message",
        description: "Add footer message for this template",
      },
      button: {
        title: "Button Message",
        description: "Add button message for this template",
      },
      buttonList: ["action"],
      process: "We are processing your request. Please wait...",
      addSecurityRecommendation: {
        title: "Add Security Recommendation",
        description: "Add security recommendation for this template",
        text: "For your security, do not share this code",
      },
      codeExpiration: {
        title: "Code Expiration",
        description: "Code expiration for this template (in minutes)",
        help: "Code expiration must be between 1 - 90 minutes",
        invalid: "You must enter a valid time expiration",
      },
      copyCode: {
        title: "Copy Code",
        description: "Set Text Button to Copy Code",
      },
    },
    language: "Language",
    none: "None",
    image: "Image",
    document: "Document",
    validUpload: "Please upload valid {{ data }}",
    imageAllowed: "Allowed image types: .jpg, .jpeg, .png",
    documentAllowed:
      "Allowed document types: .jpeg, .jpg, .png, .doc, .docx, .pdf, .csv, .ppt, .pptx, .xls, .xlsx",
    optional: "Optional",
    show: "Show",
    profileDetails: "Profile Details",
    about: "About",
    quotaDefault: "Default Quota",
    quotaFree: "Free Quota",
    balance: "Balance",
    search: "Search",
    sessionActive:
      "Please send a template message or send a message to whatsapp number to active session message reply, every session will be expired in 24 hours",
    send: "Send",
    replyMessage: "Send a reply message...",
    askReply: "Are you sure you want to send reply with {{ data }}?",
    sendReply: " {{ info }} reply with {{ data }}",
    dashboard: {
      totalInbox: "Total Inbox",
      totalOutbox: "Total Outbox",
      totalMessage: "Total Message Send",
      totalMessageFailed: "Total Message Failed",
      statistics: "Statistics",
    },
    sent: "Sent",
    failed: "Failed",
    delivered: "Delivered",
    read: "Read",
    time: "Time",
    received: "Received",
    templateName: "Template Name",
    quickReply: "Quick Reply",
    phoneNumber: "Phone Number",
    url: "URL",
    callback: "URL Callback",
    headerName: "Header Name",
    headerValue: "Header Value",
    emptyFormOptional: "Leave it empty if you want to make it optional",
    tokens: "Tokens",
    generate: "Generate",
    password: "Password",
    passwordHelp: "Enter your password to confirm",
    idToken: "ID Token",
    idTokenHelp:
      "ID Token is used to authenticate your request with API and Active For 24 Hours",
    refreshToken: "Refresh Token",
    refreshTokenHelp:
      "Refresh Token is used to refresh your ID Token and Active For Permanent, Please Keep It Safe",
    deviceId: "Device ID",
    deviceIdHelp:
      "Device ID is used to identify your device and Active For Permanent, Please Keep It Safe",
    templateNotFound:
      "Message Template Not Found, Please create new template to use this feature",
    templateNew: "Create New Template",
    templateNotApproved:
      "Your template is not approved yet, please wait for approval from whatsapp staff. If your template is rejected, you can check the reject reason. If you need help, please contact us.",
    templateReject:
      "I'm sorry, your template has been rejected. Please check the reason below.",
    templateButtonUrlHelp:
      "Your Template Button URL Require Dynamic URL in the end, Your URL {{url}} and Dynamic URL {{dynamicUrl}}. Please make sure this is same as your template button URL.",
    new: "New",
    replied: "Replied",
    button: {
      title: "Button",
      description: "Add button for this template",
    },
    from: "From",
    type: "Type",
    message: "Message",
    reduceBalance:
      "Current quotaBase {{ quotaBase }}, Current Balance {{ balance }}, Reduce From {{ reduce }} with {{ total }}, Remaining Balance {{ reduce }} {{ remaining }}",
    sessionInfo:
      "Calculate sessions are based on categories and will remain active for 24 hours. If you send a message with a different category, the session will be reopened based on that new category",
    balanceReduce:
      "Sending Message to {{ phone }}, the system will reduce your quota/balance",
    balanceNotReduce:
      "Sending Message To {{ phone}}, the system will not reduce quota/balance because the session is active (24 hours)",
    newMessage: "New Message from {{ phone }}",
    yes: "Yes",
    no: "No",
  },
};
