import { lazy } from "react";
import { Navigate } from "react-router-dom";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Layouts
const AuthLayout = lazy(() => import("./layouts/Auth"));
const DashboardLayout = lazy(() => import("./layouts/Dashboard"));
// const LandingLayout = lazy(() => import("./layouts/Landing"));

// Landing
// const Landing = lazy(() => import("./pages/landing/Landing"));

// Auth
const Page500 = lazy(() => import("./pages/auth/Page500"));
const Page404 = lazy(() => import("./pages/auth/Page404"));
const SignIn = lazy(() => import("./pages/auth/SignIn"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const ChangePassword = lazy(() => import("./pages/auth/ChangePassword"));
const NewPassword = lazy(() => import("./pages/auth/NewPassword"));

// KrmPesan Pages
const Dashboards = lazy(() => import("./pages/dashboards/Default"));
const Chat = lazy(() => import("./pages/messages/Chat"));
const ChatNew = lazy(() => import("./pages/messages/ChatNew"));
const Device = lazy(() => import("./pages/devices"));
const Contact = lazy(() => import("./pages/contacts"));
const Template = lazy(() => import("./pages/template"));
const TemplateForm = lazy(() => import("./pages/template/form"));
const Logs = lazy(() => import("./pages/logs"));
const Sessions = lazy(() => import("./pages/sessions"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard" replace />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "new-password",
        element: <NewPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboards />,
      },
    ],
  },
  {
    path: "new",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ChatNew />,
      },
    ],
  },
  {
    path: "messages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Chat />,
      },
    ],
  },
  {
    path: "template",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Template />,
      },
      {
        path: "create",
        element: <TemplateForm />,
      },
      {
        path: ":id",
        element: <TemplateForm />,
      },
    ],
  },
  {
    path: "device",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Device />,
      },
    ],
  },
  {
    path: "contacts",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Contact />,
      },
    ],
  },
  {
    path: "logs",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Logs />,
      },
    ],
  },
  {
    path: "sessions",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Sessions />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
