import { Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { SWRConfig } from "swr";

import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/id";
import "./i18n";
import routes from "./routes";

import Loading from "./components/Loading";
import { AuthProvider } from "./contexts/CognitoContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { store } from "./redux/store";

// import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";

dayjs.locale("id");

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s - ${process.env.REACT_APP_NAME}`}
        defaultTitle={process.env.REACT_APP_NAME}
      />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              {/* <ChartJsDefaults /> */}
              <AuthProvider>
                <SWRConfig
                  value={{
                    refreshInterval: 15000,
                    suspense: false,
                    fetcher: (
                      url: string,
                      token: string,
                      queryParam?: string
                    ) =>
                      fetch(queryParam ? `${url}?${queryParam}` : url, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }).then((res) => res.json()),
                  }}
                >
                  <Suspense fallback={<Loading />}>{content}</Suspense>
                </SWRConfig>
              </AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
