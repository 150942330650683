import "react-app-polyfill/stable";
import "react-loading-skeleton/dist/skeleton.css";

import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";

// import "./mocks";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://bafc1c28bba948af8c2d5e208c25c8af@o342959.ingest.sentry.io/4504761126027264",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_STAGE === "prod" ? 0.2 : 0,
  enabled: process.env.REACT_APP_STAGE === "prod",
});

ReactGA.initialize("G-PR53J83FVM");
ReactGA.send("pageview");

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
